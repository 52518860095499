import { useEffect, useState } from "react";
import {
  decryptedLocalStorage,
  encryptedLocalStorage,
  getDecryptedBody,
  getEncryptedBody,
} from "./crypto";

export const useLocalStorageService = () => {
  const [localStorageData, setLocalStorageData] = useState(null);

  const setLocalStorageRecord = (key, value) => {
    const localStorageItems = localStorage.getItem("data");
    let decryptingRecord = {};
    if (localStorageItems) {
      const decrypting = decryptedLocalStorage("123456789", localStorageItems);
      decryptingRecord = JSON.parse(decrypting);
    }
    const data = JSON.stringify({ ...decryptingRecord, [key]: value });
    const encrypting = encryptedLocalStorage("123456789", data);
    localStorage.setItem("data", encrypting);
  };
  const getLocalStorageRecord = () => {
    const value = localStorage.getItem("data");
    let decryptingRecord = {};
    if (value) {
      const decrypting = decryptedLocalStorage("123456789", value);
      decryptingRecord = JSON.parse(decrypting);
    }

    setLocalStorageData(decryptingRecord);
  };
  const removeItem = (key) => {
    localStorage.removeItem(key);
    setValue(null);
  };

  return {
    setLocalStorageRecord,
    getLocalStorageRecord,
    removeItem,
    localStorageData,
  };
};
const decryptLocalStorageData = () => {
  const value = localStorage.getItem("data");
  if (value) {
    const decrypted = decryptedLocalStorage("123456789", value);
    return JSON.parse(decrypted);
  }
  return null;
};
export const getAuthUser = () => {
  const data = decryptLocalStorageData();
  return data?.authUser;
};

export const getAuthCognitoId = () => {
  const data = decryptLocalStorageData();
  return data?.authCognitoId;
};

export const getAuthUserValidated = () => {
  const data = decryptLocalStorageData();
  
  return data?.authUserValidated;
};

export const getAuthAwsCred = () => {
  const data = decryptLocalStorageData();
  return data?.authAwsCred;
};

export const getAuthUserProfile = () => {
  const data = decryptLocalStorageData();
  return data?.authUserProfile;
};

export const getEnterVaultPin = () => {
  const data = decryptLocalStorageData();
  return data?.enterVaultPin;
};

export const homeLeadSequenceId = () => {
  const data = decryptLocalStorageData();
  return data?.homeLeadSequenceId;
};

export const autoAndHomeLeadSequenceId = () => {
  const data = decryptLocalStorageData();
  return data?.autoAndHomeLeadSequenceId;
};

export const leadCreated = () => {
  const data = decryptLocalStorageData();
  return data?.leadCreated;
};