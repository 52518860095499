import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router";
import leftHand from "../assets/images/hand_left.png";
import rightHand from "../assets/images/hand_right.png";
export default function PageNotFound() {
  const navigation = useNavigate();
  return (
    <div className="notfoundContent">
      <img alt="left hand" src={leftHand} className="leftHand" />
      <img alt="right hand" src={rightHand} className="rightHand" />
      <h1>404</h1>
      <h5>Page not found</h5>
    </div>
  );
}
