import React from "react";

const Terms = () => {
  return (
    <section className="static_page">
      <div className="container_width">
        <h1 style={{ textAlign: "start" }}>Terms of Use</h1>
        <p>Updated 12/11/23</p>
        <p>
          <b>Important.</b> Please read these Terms of Service (the “Agreement”)
          carefully because they govern all of Your interactions with Us. By
          using Our websites and/or mobile applications (collectively, the
          “Platform”), You agree to everything herein.
        </p>
        <p>
          BY USING OUR PLATFORM, YOU AGREE TO THESE TERMS OF SERVICE, INCLUDING,
          BUT NOT LIMITED TO, AGREEING TO RESOLVE ANY CONTROVERSIES AND DISPUTES
          WITH US THROUGH ARBITRATION ON AN INDIVIDUAL BASIS, WAIVING CLASS
          ACTION. REFER TO SECTION 13 FOR MORE DETAILS REGARDING YOUR RIGHTS AND
          OURS.
        </p>
        <p>
          IF YOU DO NOT AGREE TO THESE TERMS OF USE, DO NOT USE THE PLATFORM.
          Continued use of the Platform establishes Your acceptance of all terms
          and conditions in this Agreement, and future updates to these Terms of
          Service.
        </p>
        <b>DEFINITIONS.</b>
        <p>
          <b>“YOLOH,” “We,” “Our” and “Us” </b>means YOLOH, Inc. and its
          parents, subsidiaries, affiliates, agents, employees, successors and
          assigns.
        </p>
        <p>
          <b>“App”</b> means the YOLOH mobile application(s).
        </p>
        <p>
          <b>“Device”</b> means any mobile phone, computer hardware or network
          used to access YOLOH Products and services.
        </p>
        <p>
          <b>“Platform” </b>means the YOLOH websites and mobile Apps, software,
          associated upgrades, patches, and updates and related services
          currently provided, or which will be provided by YOLOH, or any one of
          its subsidiaries or affiliated companies, without limitation.
        </p>
        <p>
          <b> “Product”</b> means any component, data or subset of information
          found within the Platform or public website.
        </p>
        <p>
          <b> “Subscriber” </b>means any individual or entity that has willingly
          signed up, namely by downloading and registering an Account via the
          YOLOH App and/or subscribing via the YOLOH public website.
        </p>
        <p>
          <b>“You,” “Your” and “Licensee” </b>means an individual Subscriber,
          family member or entity utilizing the App.
        </p>
        <b>1. YOUR ACCEPTANCE OF OUR TERMS.</b>
        <p>
          These Terms of Service apply to all Subscribers of the Platform,
          without limitation.
        </p>
        <p>
          <b>1.1</b> By registering for and using the Platform in any manner,
          You agree to these Terms of Service and all published policies,
          procedures and rules We publish and update, all of which are
          incorporated by reference. You can find the most current versions of
          such governing documents on Our public website. An example is Our
          Privacy Policy.
        </p>
        <p>
          <b>1.2</b> By registering on the Platform, You attest You are at least
          18 years old, have the authority to enter into this Agreement and that
          You represent Yourself, not pretending to be someone else or another
          entity. You hereby provide explicit consent to YOLOH to use the
          personal information You provide, or that which we collect, for the
          business purposes of, without limitation: (not all-inclusive)
        </p>
        <ul>
          <li>
            Providing initial quotes and subsequent re-quotes of insurance,
            warranty and other products and services within Our scope.
          </li>
          <li>
            Engaging with various third-party providers of data, such as credit
            reporting agencies, insurance aggregator databases (underwriter,
            claims and loss exchanges), motor vehicle and/or driving records and
            any other third-party We deem necessary to execute the YOLOH mission
            to serve You.
          </li>
          <li>
            Consent to YOLOH monitoring when You are using the App, with
            information that may be communicated back to YOLOH, including the
            name of Your Account, Your internet protocol (IP) address, details
            about Your usage, with or without additional notice to You.
          </li>
        </ul>
        <b>2. REGISTRATION ELIGIBILITY & AGE.</b>
        <p>
          The YOLOH Platform is meant for individuals 18 years of age and older,
          and is offered only for Your use, and not for the use or benefit of
          any third-party. If You do not meet this age requirement, You may not
          use this Platform under any circumstances.
        </p>
        <p>
          The YOLOH Platform requires You to register for an account (an
          “Account”). You agree to provide information that is complete,
          accurate and kept updated. You agree to:
        </p>
        <ul>
          <li>
            Never register any other person without their explicit
            authorization.
          </li>
          <li>
            Never attempt to impersonate any other individual or use fictitious
            registration information.
          </li>
          <li>
            {" "}
            Be solely responsible for keeping Your login credentials private and
            secure, and be accountable for all activity that occurs on Your
            Account.
          </li>
          <li>
            Notify Us immediately in the event of unauthorized use or security
            breach of Your Account.
          </li>
          <li>Only use the Platform for the prescribed purposes.</li>
          <li>
            Delete Your Account and uninstall the mobile application (the “App”)
            at any point where You ultimately determine You have no intent to
            use the Platform anymore.
          </li>
          <li>
            Refrain from using the Platform upon Our demand, including
            uninstalling the App.
          </li>
          <li>
            Immediately notify YOLOH if Your registration eligibility changes.
            For Partners (i.e. Agents, Brokers, Carriers, etc. ), this includes
            expiration, loss or revocation of requisite licenses.
          </li>
        </ul>
        <p>
          <b>2.1 </b>Refusal of Service. We reserve the right to refuse use of
          Our Platform for any reason at any time, with or without notification.
        </p>
        <b>3. PLATFORM CONTENT.</b>
        <p>
          The content displayed on Our Platform may come from a variety of
          sources, including YOLOH, Subscribers, business partners and other
          third-parties.
        </p>
        <p>
          <b>3.1 Subscriber Content.</b> All content provided, submitted,
          created, added, posted and uploaded by You shall be referred to as
          “Subscriber Content.” As the person supplying the Subscriber Content,
          it is Your sole responsibility to ensure accuracy, truth, completeness
          and compliance with all laws, regulations and rules. You agree that
          all Subscriber Content shall be used by Us in executing all Our
          duties, goals and objectives, without restriction, and You are solely
          responsible for all damage or loss to You or any other third-party. We
          make no guarantees on the use of every component of Subscriber Content
          received, nor the accuracy and completeness of services rendered to
          You via Our Platform or through third-party links.
        </p>
        <p>
          <b>3.2 YOLOH Content.</b> This includes all content provided,
          submitted, created, added, posted and uploaded by Us, thereby
          controlled by Us.
        </p>
        <p>
          <b> 3.3 Business Partner and Third-Party Content.</b> This includes
          all content provided, submitted, created, added, posted and uploaded
          by external entities, many of which are contracted business partners.
          YOLOH has no ability to control such content, therefore bears no
          responsibility for it.
        </p>
        <p>
          <b>3.4 Legal Notices and Limitations.</b> All content, regardless of
          source, is protected by trademarks, service marks, copyrights,
          patents, intellectual property, trade secrets or other proprietary
          rights and laws. You agree to abide by all such rules, regulations and
          restrictions when using the Platform.
        </p>
        <b>4. LICENSE GRANT, USE & RESTRICTIONS.</b>
        <p>
          <b>4.1 License Grant.</b> Contingent upon the terms set forth in in
          this Agreement, YOLOH grants You a non-exclusive, non-transferable,
          non-sublicensed, non-commercial and personal license (“License”) to
          use the Platform and install and use the App (in whole or in part) on
          any Device owned and controlled by You for such time until either You
          or YOLOH terminates this License. You agree to operate strictly in
          accordance with the terms and conditions of this License, and all
          applicable local, national, and international laws and regulations.
          You must neither use, nor allow others to use, the Platform or this
          License for commercial purposes without obtaining a License from
          YOLOH. Updates, upgrades, patches and modifications may be necessary
          in order to be able to continue to use the Platform on certain
          hardware. You agree to abide by the rules and policies We establish
          from time to time, and these obligations survive termination of this
          License. THIS PLATFORM IS LICENSED TO YOU, NOT SOLD. You may not sell,
          rent out, lease, license, distribute, market, exploit the Platform or
          any of its parts commercially.
        </p>
        <p>
          <b>4.2 Compliance & Behavior.</b> While using the Platform, You agree
          to comply with all applicable laws, rules and regulations. You also
          agree to comply with certain rules of conduct that govern Your use of
          the Platform, which are not meant to be exhaustive and can be modified
          at any time by YOLOH. In all cases, You may only use the Platform
          according to intended use of the Platform. For example, and without
          limiting YOLOH’s rights to take action against Subscribers, You may
          not:
        </p>
        <ul>
          <li>
            create, use, share and/or publish by any means in relation to the
            Platform any material (text, words, images, sounds, videos, etc.)
            which You know to be fraudulent, false, misleading, untruthful,
            deceptive, inaccurate, or a breach of a duty of confidentiality,
            infringe any intellectual property right or an individual’s right to
            privacy or which would incite the committing of an unlawful act;
          </li>
          <li>
            reverse engineer, decompile, disassemble, adapt, reproduce, or
            create derivative works of this Platform, in whole or in part;
          </li>
          <li>
            modify, hack, distort, block, crawl, spider, harvest or scrape,
            abnormally burden, disrupt, slow down and/or hinder the normal
            functioning of all or part of the Platform, or accessibility to
            other Subscribers, or the overall functioning of the Platform, or
            attempt to do any of the above;
          </li>
          <li>
            transmit or propagate any virus, trojan horse, worm, bomb, corrupted
            file and/or similar destructive device or corrupted data in relation
            to the Platform, and/or organize, participate in or be involved in
            any way in an attack on YOLOH’s servers and/or the Platform and/or
            those of its service providers and partners;
          </li>
          <li>
            {" "}
            create, supply or use alternative methods of using the Platform, for
            example server emulators, to bypass, circumvent or attempt to bypass
            or circumvent any measures We may use to prevent or restrict access
            to the Platform (or other accounts, computer systems or networks
            connected to the Platform);
          </li>
          <li>
            engage in unauthorized or unsolicited advertising, run any form of
            auto-response, junk or bulk e-mail (“spamming”), whether for
            personal or commercial purposes, by disrupting the flow of
            conversation with repeated postings of a similar nature;
          </li>
          <li>
            transmit or communicate any material or content which, in the sole
            and exclusive discretion of YOLOH, is believed or deemed offensive,
            including, but not limited to, content that is harmful, threatening,
            unlawful, abusive, harassing, defamatory, tortious, libelous,
            disparaging, obscene, vulgar, pornographic, profane, sexually
            explicit, or racially, ethnically or otherwise objectionable;
          </li>
          <li>
            make inappropriate use of the help service or the claim
            buttons/links, or send untruthful reports to YOLOH personnel;
          </li>
          <li>
            falsely claim to be an employee or representative of YOLOH or its
            partners and/or agents, or falsely claim an endorsement in
            connection with the Platform or with YOLOH;
          </li>
          <li>
            {" "}
            otherwise take any action in violation of Our guidelines and
            policies or allow a third-party to do so.
          </li>
        </ul>
        <p>
          <b>4.3 Legal and Compliance.</b> We also reserve the right to access,
          read, preserve and disclose any information as We reasonably believe
          is necessary to (1) satisfy any applicable law, regulation, legal
          process or governmental request, (2) enforce these Terms of Service,
          including investigation of potential violations hereof, (3) detect,
          prevent, or otherwise address fraud, security or technical issues, (4)
          respond to Subscriber support requests, and (5) protect the Our
          rights, property or safety, as well as that of Our Subscribers and the
          public.
        </p>
        <b>4.4 Intentionally Left Blank.</b>
        <p>
          <b>4.5 Improper Use of the Platform Prohibited.</b> In addition to the
          termination provisions in Section 9, at YOLOH’S sole discretion, We
          may terminate Your use of the Platform for improper usage of it.
        </p>
        <p>
          <b>4.6 Availability of Content.</b> YOLOH does not guarantee that any
          Content, regardless of source, will be made available on the Platform.
          We reserve the right, at any time and for any reason, at Our sole
          discretion, to edit, modify, block or delete any Content.
        </p>
        <p>
          <b>5. THE APP – VOICE, DATA & MESSAGING.</b>
        </p>
        <p>
          The Platform allows You to solicit insurance premium quotations and
          policies from insurance companies and their agents, employees and/or
          brokerages. Additional insurance related content will also be made
          available. Voice, data and messaging rates may apply when You use the
          App.
        </p>
        <p>
          You agree and provide your express consent to receiving recurring and
          automated emails, text messages, calls, and pre-recorded messages from
          YOLOH and its partners at the number used when signing up, solely for
          the purpose of discussing your options and answering any questions you
          may have. You understand that your consent is voluntary and that you
          can revoke this permission anytime by replying “STOP” to cancel
          receiving text messages or by clicking the “UNSUBSCRIBE” link within
          the body of the email. Message frequency varies. Message rates may
          apply.
        </p>
        <p>
          MyLifeProtected is a service of MassDrive Insurance Group, LLC, the
          licensed agency for all products. View the licensing information for
          MassDrive Insurance
          <a href="https://bindable.com/licensing-information" target="_blank">
           {" "} <span className="groupHere">Group here.</span>
          </a>
        </p>
        <b>6. OWNERSHIP & INTELLECTUAL PROPERTY RIGHTS.</b>
        <p>
          <b>6.1 Rights to Platform. </b>All title, ownership rights and
          intellectual property rights in and to the Platform and public website
          (including, without limitation, all source and object code, text,
          graphics, workflow, items of information, fictional characters,
          objects, scenery, slogans, diagrams, concepts, videos, audio-visual
          effects, domain names and any other elements which are part of the
          Platform, individually or in combination) and any and all copies
          thereof are owned by YOLOH or its licensors and collaborators. The
          Platform is protected by national and international laws, copyright
          treaties and conventions and other laws. This Platform may contain
          certain licensed materials and, in that event, YOLOH’s licensors may
          protect their rights in the event of any violation of this Agreement.
          Any reproduction or representation of these licensed materials in any
          way and for any reason is prohibited without YOLOH’s prior permission
          and, if applicable, YOLOH’s licensors’ and representatives’. Except as
          expressly set forth in this Agreement, all rights not granted
          hereunder to You are expressly reserved by YOLOH. This License confers
          no title or ownership in the Platform and should not be construed as a
          sale of any rights by YOLOH.
        </p>
        <p>
          YOLOH and its collaborators, licensors, and suppliers reserve the
          right to change, suspend, terminate, remove, impose limits on the use
          of or access to, disable access to, or require the return of the
          Platform (or any copy thereof) at any time without notice and will
          have no liability for doing so. Except as expressly stated in this
          License, You are not granted any intellectual property rights in or to
          the Platform by implication, estoppel or other legal theory, and We
          reserve and retain all rights in and to the Platform not expressly
          granted in this License. These obligations survive termination of this
          License.
        </p>
        <p>
          <b>6.2 YOLOH Marks.</b> The following company names and their related
          logos and all related product and service names, design marks and
          slogans are trademarks and service marks owned by and used under
          license from YOLOH: “YOLOH” (the “YOLOH Marks”). You are not
          authorized to use the YOLOH Marks in any advertising, publicity or in
          any other commercial manner without the prior written consent of
          YOLOH, which may be withheld for any or no reason. These obligations
          survive termination of this License.
        </p>
        <b>7. INSURANCE OPTIONS.</b>
        <p>
          Through Our Platform, We will provide information on various
          third-party insurance products, coverages and other similar products
          and services. This information should neither be presented nor
          construed as insurance advice from Us. Our intent is to present you
          with information and marketplace options. It is Your duty to evaluate
          and interpret the suitability against Your specific needs, and to make
          purchase decisions. We do not guarantee that quotes, fees, terms,
          rates, coverage or services offered are suitable or the best
          available. We recommend getting the advice of qualified professionals
          to assist in making insurance decisions.
        </p>
        <b>8. THIRD-PARTY LINKS.</b>
        <p>
          YOLOH will display hyperlinks to components of Our own Platform, as
          well as links/paths to third-party websites as a convenience to the
          public. YOLOH is not responsible for, and expressly disclaims all
          liability for, damages of any kind arising out of use, reference to,
          or reliance on any information contained within the sites (including
          third-party sites). While the information contained within Our
          Platform is periodically updated, no guarantee is given that the
          information provided in the sites is correct, complete and up-to-date.
          YOLOH offers links to other third-party websites that may be of
          interest to Our Platform visitors. The links provided in Our Platform
          are provided solely for Your convenience and may assist You in
          locating other useful information on the internet. When you click on
          these links you will leave Our Platform and will be redirected to
          another site. These sites are not under the control of YOLOH. We make
          no representations regarding the suitability of content in such sites
          for any particular audience. YOLOH is not responsible for any of the
          content of linked third-party websites. We are not an agent for these
          third-parties nor do We endorse or guarantee (expressly or implied)
          their products or their website content. We make no representation or
          warranty regarding the accuracy of the information contained in the
          linked sites. We do not promise to validate third-party offers,
          pricing, licenses, certifications, qualifications or other claims made
          by any third-parties on Our Platform, in general. We suggest that you
          always verify the information obtained from linked websites before
          acting upon this information. Also, be aware that the security and
          privacy policies on these third-party sites may be different from
          YOLOH policies, so please read third-party privacy and security
          policies closely. If you have any questions or concerns about the
          products and services offered on linked third-party websites, please
          contact the third-party directly.
        </p>
        <b>9. TERM AND TERMINATION.</b>
        <p>
          This License shall be effective until terminated. YOLOH may, in its
          sole and absolute discretion, at any time and for any or no reason,
          disable the Platform, or suspend or terminate this License and the
          rights afforded to You under this License, with or without prior
          notice or other action by YOLOH. Upon the termination of this License,
          You shall cease all use of the Platform and uninstall the App. YOLOH
          will not be liable to You or any third-party for compensation,
          indemnity, or damages of any sort as a result of terminating this
          License in accordance with its terms, and termination of this License
          will be without prejudice to any other right or remedy YOLOH may have,
          now or in the future. These obligations survive termination of this
          License. Should You wish to terminate App use or this License, You
          must simply uninstall and remove the App from Your Device, and delete
          any copy in Your possession. This License will automatically terminate
          if You are no longer a YOLOH Subscriber.
        </p>
        <b>10. DISCLAIMER OF WARRANTIES.</b>
        <p>
          YOU ACKNOWLEDGE AND AGREE THAT THE PLATFORM, INCLUDING ALL CONTENT
          CONTAINED THEREIN OR ACCESSED THEREBY, IS PROVIDED ON AN “AS IS” AND
          “AS AVAILABLE” BASIS, AND THAT YOUR USE OF OR RELIANCE UPON THE
          PLATFORM IS AT YOUR SOLE RISK AND DISCRETION. TO THE EXTENT NOT
          PROHIBITED BY LAW, YOLOH AND ITS COLLABORATORS, SUPPLIERS AND
          LICENSORS HEREBY DISCLAIM ANY AND ALL REPRESENTATIONS, WARRANTIES AND
          GUARANTEES REGARDING THE PLATFORM, WHETHER ORAL, EXPRESS, IMPLIED OR
          STATUTORY, AND WHETHER ARISING BY LAW, STATUTE, USAGE OF TRADE,
          CUSTOM, COURSE OF DEALING OR PERFORMANCE OF THE PARTIES, OR THE NATURE
          OR CONTEXT OF THIS LICENSE, AND INCLUDING, WITHOUT LIMITATION, THE
          IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
          PURPOSE, AND NON-INFRINGEMENT. FURTHERMORE, YOLOH AND ITS
          COLLABORATORS, SUPPLIERS AND LICENSORS MAKE NO WARRANTY THAT (I) THE
          PLATFORM WILL MEET YOUR REQUIREMENTS; (II) THE PLATFORM WILL BE
          UNINTERRUPTED, ACCURATE, RELIABLE, TIMELY, SECURE, FREE FROM VIRUSES
          OR OTHER HARMFUL COMPONENTS OR ERROR-FREE; (III) THE QUALITY OF ANY
          PRODUCTS, SERVICES, INFORMATION OR OTHER MATERIAL ACCESSED OR OBTAINED
          BY YOU THROUGH THE PLATFORM WILL BE AS REPRESENTED OR MEET YOUR
          EXPECTATIONS; OR (IV) ANY ERRORS IN THE PLATFORM WILL BE CORRECTED OR
          THAT THE PLATFORM WILL BE MAINTAINED. YOU ACKNOWLEDGE THAT THE
          PLATFORM IS NOT INTENDED OR SUITABLE FOR USE IN SITUATIONS OR
          ENVIRONMENTS WHERE THE PERFORMANCE OF, USE OR MISUSE OF, FAILURE OF,
          OR ERRORS OR INACCURACIES IN THE CONTENT, DATA OR INFORMATION PROVIDED
          BY, THE PLATFORM COULD LEAD TO DEATH, PERSONAL INJURY, OR SEVERE
          PHYSICAL, PROPERTY, OR ENVIRONMENTAL DAMAGE. YOLOH AND ITS
          COLLABORATORS, SUPPLIERS AND LICENSORS DO NOT WARRANT THAT THE
          PLATFORM WILL BE COMPATIBLE OR INTEROPERABLE WITH YOUR DEVICE OR ANY
          OTHER PIECE OF HARDWARE, SOFTWARE, EQUIPMENT OR DEVICE INSTALLED ON OR
          USED IN CONNECTION WITH YOUR DEVICE. FURTHERMORE, YOU ACKNOWLEDGE THAT
          COMPATIBILITY AND INTEROPERABILITY PROBLEMS CAN CAUSE THE PERFORMANCE
          OF YOUR DEVICE TO DIMINISH OR FAIL COMPLETELY, AND MAY RESULT IN
          PERMANENT DAMAGE TO YOUR DEVICE, LOSS OF THE DATA LOCATED ON YOUR
          DEVICE, AND CORRUPTION OF THE SOFTWARE AND FILES LOCATED ON YOUR
          DEVICE. YOU ACKNOWLEDGE AND AGREE THAT YOLOH AND ITS COLLABORATORS,
          SUPPLIERS AND LICENSORS, AND THEIR OFFICERS, DIRECTORS, EMPLOYEES AND
          AGENTS, SHALL HAVE NO LIABILITY TO YOU FOR ANY LOSSES SUFFERED,
          RESULTING FROM OR ARISING IN CONNECTION WITH COMPATIBILITY OR
          INTEROPERABILITY PROBLEMS. SHOULD THE PLATFORM PROVE DEFECTIVE, YOU
          ASSUME THE ENTIRE BURDEN OF ALL NECESSARY EXPENSES, SERVICING, REPAIR,
          OR CORRECTION. THIS SECTION 6 SHALL SURVIVE TERMINATION OF THIS
          LICENSE. NOTWITHSTANDING AND WITHOUT WAIVING THE FOREGOING, THE TERMS
          AND CONDITIONS OF STATE LAW MAY PROVIDE LIMITED REMEDIES TO YOU.
        </p>
        <b>11. LIMITATION OF LIABILITY.</b>
        <p>
          EXCEPT TO THE EXTENT PROHIBITED BY LAW, UNDER NO CIRCUMSTANCES SHALL
          YOLOH, ITS COLLABORATORS, SUPPLIERS OR LICENSORS, NOR THEIR OFFICERS,
          DIRECTORS, EMPLOYEES AND AGENTS, BE LIABLE FOR ACCIDENTS, PROPERTY
          DAMAGE, PERSONAL INJURY, DEATH, OR FOR ANY INDIRECT, INCIDENTAL,
          CONSEQUENTIAL, PUNITIVE, SPECIAL OR EXEMPLARY DAMAGES ARISING OUT OF
          OR IN CONNECTION WITH OR RELATING TO THE SALE OR DISTRIBUTION OF, THE
          PERFORMANCE OR NON-PERFORMANCE, OR YOUR ACCESS OR USE OF OR INABILITY
          TO ACCESS OR USE THE PLATFORM, WHETHER OR NOT THE DAMAGES WERE
          FORESEEABLE AND WHETHER OR NOT SUCH PARTY WAS ADVISED OF THE
          POSSIBILITY OF SUCH DAMAGES. WITHOUT LIMITING THE GENERALITY OF THE
          FOREGOING, YOLOH'S AGGREGATE LIABILITY TO YOU (WHETHER UNDER CONTRACT,
          TORT, STATUTE OR OTHERWISE) SHALL NOT EXCEED THE GREATER OF (i) THE
          INITIAL PURCHASE PRICE PAID BY YOU FOR THE PLATFORM, (ii) THE
          AGGREGATE AMOUNT YOU PAID TO YOLOH FOR THE PLATFORM DURING THE ONE
          MONTH PRECEDING THE DATE THAT THE CLAIM ARISES. THE FOREGOING
          LIMITATIONS WILL APPLY EVEN IF THE ABOVE STATED REMEDY FAILS OF ITS
          ESSENTIAL PURPOSE. THIS SECTION 7 SHALL SURVIVE TERMINATION OF THIS
          LICENSE.
        </p>
        <b>12. INDEMNIFICATION.</b>
        <p>
          You shall indemnify, defend and hold harmless YOLOH and its
          collaborators, suppliers and licensors, and their officers, directors,
          agents and employees (the "Indemnified Parties") from and against any
          claim, proceeding, loss, damage, fine, penalty, interest and expense
          (including, without limitation, fees for attorneys and other
          professional advisors) arising out of, in connection with or related
          to the following: (1) Your access to or use of the Platform or
          Third-Party Content and Services; (2) Your breach of this License; (3)
          Your violation of law; (4) Your negligence or willful misconduct; or
          (5) Your violation of the rights of a third-party. You will promptly
          notify YOLOH in writing of any third-party claim arising out of or in
          connection with Your access to or use of the Platform. These
          obligations survive termination of this License.
        </p>
        <b>13. GENERAL PROVISIONS.</b>
        <p>
          <b>13.1 Governing Law, Limitation on Actions.</b> This License shall
          be deemed to take place in the State of Ohio and shall be governed by
          and construed in accordance with the laws of the State of Ohio,
          without regard to its conflicts of law principles. This License shall
          not be governed by the United Nations Convention on Contracts for the
          International Sale of Goods, the application of which is expressly
          excluded. YOUR LEGAL RIGHTS ARE IMPACTED HERE – PLEASE READ CAREFULLY
        </p>
        <p>
          <b>13.2 Arbitration; Jury & Class Action Waiver.</b> You agree that
          any controversies or disputes arising out of these Terms of Service,
          its interpretation or performance, shall be settled by INDIVIDUAL
          binding arbitration in accordance with American Arbitration
          Association (“AAA”) rules for consumer related disputes, rather than a
          court. As a result, You agree to waive rights to a jury trial as well
          as class action pursuits, eliminating Your participation as part of a
          class, including as a class representative. CLASS, REPRESENTATIVE OR
          COLLECTIVE ACTIONS ARE NOT PERMITTED.
        </p>
        <p>
          The venue for any arbitration under these Terms of Service shall be in
          Franklin County, Ohio, the proceedings shall be conducted in English,
          and the judgment upon award may be entered in any court having
          jurisdiction thereof. In addition to any other relief(s) granted in an
          award, the prevailing party shall also be entitled to an award of
          arbitration/attorney's fees and costs.
        </p>
        <p>
          To the extent that You violate or threaten to violate YOLOH
          Intellectual Property Rights, We may seek injunctive relief (and/or
          other relief) via any federal or state court in Ohio.
        </p>
        <b>Regarding the arbitration model:</b>
        <p>
          <b>A.</b> All arbitration shall be conducted in the state of Ohio, or
          a venue of YOLOH’s choosing. If permitted by the arbitrator, in-person
          attendance is optional as You might be allowed to join via phone or
          written submission.
        </p>
        <p>
          <b>B.</b> There is no court, judge or jury.
        </p>
        <p>
          <b>C.</b> Arbitrator decisions are binding and generally final.
        </p>
        <p>
          <b>D.</b> Arbitrators have similar authority to judges, granting
          injunctive relief (and similar), remedies and awards on an INDIVIDUAL
          basis. However, some common legal components you are accustomed to via
          court proceedings may be limited or non-existent. Discovery is an
          example
        </p>
        <p>
          <b>E.</b> You agree that all state laws are preempted by the AAA
          rules, which may be found at www.adr.org. Otherwise, laws of the State
          of Ohio will govern.
        </p>
        <p>
          <b>F.</b> Should the AAA be unable to provide a hearing date within
          one hundred eighty (180) days of filing the arbitration petition, We
          shall have the option to consider alternative arbitration via
          mediation services or judicial arbitration.
        </p>
        <p>
          To the maximum extent permitted by applicable law, You and YOLOH agree
          that any cause of action arising out of or relating to this License,
          the App or Your use of the Platform must commence within one (1) year
          after the applicable cause of action accrues in accordance with the
          dispute resolution provision. Otherwise, such cause of action is
          permanently barred.
        </p>
        <p>
          <b>13.3 Force Majeure.</b> In the event performance of this License,
          or any obligation hereunder, is either directly or indirectly
          prevented, restricted, or interfered with resulting from any cause
          beyond the reasonable control of YOLOH, including acts of nature, acts
          of God, acts of civil or military authority, any law, order,
          regulation or ordinance of any government or legal body, embargoes,
          epidemics, sabotage, failure of suppliers of materials, act of the
          public enemy, war, terrorist acts, riots, insurrections, fires,
          explosions, earthquakes, nuclear accidents, floods, work stoppages,
          power blackouts, breakage or accident to machinery or equipment,
          unusually severe weather, or any other circumstances beyond the
          reasonable control and without the fault or negligence of Ours, YOLOH,
          upon giving prompt notice to Licensee, shall be excused from such
          performance on a day-to-day basis to the extent of such prevention,
          restriction, or interference (and Licensee shall likewise be excused
          from performance of its obligations on a day-to-day basis until the
          delay, restriction or interference has ceased); provided however, that
          YOLOH shall use diligent efforts to avoid or remove such causes of
          non-performance and both YOLOH and Licensee shall proceed in
          accordance with the License whenever such causes are removed or cease.
        </p>
        <p>
          <b>13.4 Severability.</b> If any provision of this License is held to
          be invalid or unenforceable, the remaining provisions shall not be
          affected and shall remain valid and enforceable to the fullest extent
          permitted by law.
        </p>
        <p>
          <b>13.5 Waiver.</b> Except as provided herein, the failure to exercise
          a right or require performance of an obligation under this License
          shall not affect a party's ability to exercise such right or require
          such performance at any time thereafter nor shall the waiver of a
          breach constitute waiver of any subsequent breach.
        </p>
        <p>
          <b>13.6 Export Control; Lawful Use.</b> You may not export or
          re-export the Platform except as authorized by United States law and
          the laws of the jurisdiction(s) in which the Platform was obtained.
          You represent and warrant that You are not located and will not use
          the Platform in any country that is (a) subject to a U.S. Government
          embargo, or that has been designated by the U.S. Government as a
          “terrorist supporting” country, or (b) listed on any U.S. Government
          list of prohibited or restricted parties including the Treasury
          Department's list of Specially Designated Nationals or the U.S.
          Department of Commerce Denied Persons List or Entity List. You may not
          use the Platform in any manner or for any purpose prohibited (a) by
          United States law, regardless of where You use the Platform, or (b) by
          local law, in the jurisdiction(s) in which You use the Platform.
        </p>
        <p>
          <b>13.7 Performance or Benchmark Testing. </b>You may not disclose the
          results of any YOLOH Platform performance or benchmark testing to any
          third-party without YOLOH's prior written approval.
        </p>
        <p>
          <b>13.8 Modification or Amendment. </b>To the extent not prohibited by
          law, YOLOH may modify or amend the terms of this License at any time,
          with or without direct notice to You, by posting a copy of the
          modified or amended License available via YOLOH’s public website
          www.yoloh.com
        </p>
        <p>
          You will be deemed to have agreed to any such modification or
          amendment by Your decision to continue using the Platform following
          the date in which the modified or amended License is made available
          through the Platform or the referenced website.
        </p>
        <p>
          <b>13.9 Survival.</b> Any provisions of this License which by their
          express language or by their context are intended to survive the
          termination of this License shall survive such termination.
        </p>
        <p>
          <b>13.10 Third-Party Beneficiaries. </b>Except as explicitly provided
          in this License or in incorporated agreements, nothing contained in
          this License is intended or shall be construed to confer upon any
          person (other than the Parties hereto) any rights, benefits or
          remedies of any kind or character, or to create any obligations or
          liabilities of a Party to any such person.
        </p>
        <p>
          <b>13.11 No Transfer By You. </b>Any attempted transfer by You in
          contravention of this License shall be null and void. These
          obligations survive termination of this License. YOLOH may assign this
          License without restriction.
        </p>
        <p>
          <b>13.12 Copyright Complaints.</b> YOLOH respects the intellectual
          property rights of others. If You believe that Your work has been
          copied and has been posted, stored or transmitted by or through the
          Platform in a way that constitutes copyright infringement, please send
          Us a notification pursuant to the Digital Millennium Copyright Act
          (“DMCA”). Please send Your notification to the contact in Paragraph
          13.18 (Contact Information) below.
        </p>
        <p>
          <b>13.13 Payment, Billing & Compensation.</b> We are a for-profit
          insurance marketplace, intent on delivering unparalleled speed,
          simplicity and overall satisfaction to You. As such, we may receive
          compensation or commission from others, including Our third-party
          insurers, agents, brokers, advertisers and others. At present, there
          are no fees to consumers to use Our Platform and we do not currently
          anticipate any consumer fees. We reserve the right to make future
          changes via a reasonable and customary method.
        </p>
        <p>
          <b>13.14 Notices.</b> Unless otherwise specified in these Terms of
          Service, all notices shall be in writing. Notices will be deemed to
          have been given; a) upon receipt when provided in person, via
          certified/registered mail or parcel delivery service; b) upon
          confirmation when received via electronic mail, or 24-hours after sent
          electronically. Electronic notices must be sent to connect@yoloh.com
        </p>
        <p>
          <b>13.15 Headings.</b> All section and paragraph headings in these
          Terms of Service are for convenience only. Headings shall not affect
          the interpretation of contents therein.
        </p>
        <p>
          <b>13.16 Agency. </b>No agency, partnership, joint venture, or
          employment relationship is created as a result of these Terms of
          Service and neither party has any authority of any kind to bind the
          other in any respect.
        </p>
        <p>
          <b>13.17 Entire Agreement.</b> This License, including the documents
          incorporated herein by reference, constitute the entire Agreement with
          respect to the use of the Platform licensed hereunder and supersedes
          all prior or contemporaneous understandings regarding such subject
          matter.
        </p>
        <p>
          <b>13.18 Contact Information.</b> You may reach YOLOH by visiting
          www.yoloh.com
        </p>
        <p>
          YOLOH Compliance Dept
          <br />
          6660 N. High St., Suite 3-H
          <br />
          Worthington, Ohio 43085
        </p>
        <b>connect@yoloh.com</b>
      </div>
    </section>
  );
};

export default Terms;
