import React, { useEffect, useRef, useState } from "react";
import "./dropStyle.css";
import { IconButton } from "@mui/material";
import { Cancel } from "@mui/icons-material";
import profileIcon from "../../assets/images/profile-thumb.svg";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";

const maxSizeInBytes = 1024 * 1024;

export default function FilesDragAndDrop({
  onUpload,
  children,
  count,
  formats,
  defaultFile,
  setValue,
  setMessage,
  message,
  control,
  name,
  style = {},
  accept,
  disabled = false,
}) {
  const [dragging, setDragging] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);

  const drop = useRef(null);
  const drag = useRef(null);
  const fileInput = useRef(null);

  // const [message, setMessage] = useState({
  //   show: false,
  //   text: null,
  //   type: null,
  // });

  const showMessage = (text, type, timeout) => {
    setMessage({
      show: true,
      text,
      type,
    });
    if (text?.length > 0) {
      setSelectedDocument(null);
    }
    // setTimeout(
    //   () =>
    //     setMessage({
    //       show: false,
    //       text: null,
    //       type: null,
    //     }),
    //   timeout
    // );
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  useEffect(() => {
    if (defaultFile?.length || defaultFile instanceof File) {
      if (defaultFile instanceof File && defaultFile.size > 1 * 1024 * 1024) {
        // enqueueSnackbar("File size exceed, file must be less than 1 mb.", {
        //   variant: "error",
        // });
        handleRemoveFile();
        return;
      }
      setSelectedDocument(defaultFile);
    }
  }, [defaultFile]);

  useEffect(() => {
    drop?.current?.addEventListener("dragover", handleDragOver);
    drop?.current?.addEventListener("drop", handleDrop);
    drop?.current?.addEventListener("dragenter", handleDragEnter);
    drop?.current?.addEventListener("dragleave", handleDragLeave);

    return () => {
      drop?.current?.removeEventListener("dragover", handleDragOver);
      drop?.current?.removeEventListener("drop", handleDrop);
      drop?.current?.removeEventListener("dragenter", handleDragEnter);
      drop?.current?.removeEventListener("dragleave", handleDragLeave);
    };
  }, []);

  const handleRemoveFile = () => {
    setSelectedDocument(null);
    setDragging(false);
    // setValue(name, []);
    if (fileInput.current) {
      fileInput.current.value = "";
    }
    if (defaultFile?.length) {
      // setValue(name, []);
    }
  };
  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (drag) {
      if (e.target !== drag?.current) {
        setDragging(true);
      }
    }
  };
  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (drag) {
      if (e.target === drag?.current) {
        setDragging(false);
      }
    }
  };
  const validateFiles = (files, config) => {
    const {
      maxSizeInBytes,
      showMessage,
      onUpload,
      formats,
      successDuration,
      errorDuration,
    } = config;
    if (files.length !== 1) {
      showMessage(
        `Nope, only 1 file can be uploaded at a time`,
        "error",
        errorDuration
      );
      return;
    }
    const oversizedFiles = Array.from(files).filter(
      (file) => file.size > maxSizeInBytes
    );
    if (oversizedFiles.length > 0) {
      showMessage(
        `Nope, file size should be up to ${
          maxSizeInBytes / (1024 * 1024)
        } MB. The following file(s) are too large: ${oversizedFiles
          .map((file) => file.name)
          .join(", ")}`,
        "error",
        errorDuration
      );
      return;
    }
    if (
      formats &&
      Array.from(files).some(
        (file) =>
          !formats.some((format) =>
            file.name.toLowerCase().endsWith(format.toLowerCase())
          )
      )
    ) {
      showMessage(
        `Nope, only following file formats are acceptable: ${formats.join(
          ", "
        )}`,
        "error",
        errorDuration
      );
      return;
    }
    showMessage("Yep, that's what I want", "success", successDuration);
    setSelectedDocument(files[0]);
    onUpload(files[0]);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    const files = [...e.dataTransfer.files];
    // if (!selectedDocument) {
    setDragging(false);
    validateFiles(files, {
      count,
      maxSizeInBytes,
      showMessage,
      onUpload,
      formats,
      successDuration: 1000,
      errorDuration: 2000,
    });
    // }
  };

  const handleFileInputChange = (e) => {
    const files = e.target.files;
    validateFiles(Array.from(files), {
      count,
      maxSizeInBytes,
      showMessage,
      onUpload,
      formats,
      successDuration: 1000,
      errorDuration: 2000,
    });
    setDragging(false);
  };

  const handleClick = () => {
    if (fileInput) {
      fileInput.current.click();
    }
  };

  return (
    <>
      {/* <>
        {(selectedDocument || defaultFile?.length > 0) && (
          <>
            <img
              src={
                selectedDocument instanceof Blob ||
                selectedDocument instanceof File
                  ? URL.createObjectURL(selectedDocument)
                  : defaultFile
              }
              alt="Upload Icon"
              width="120px"
              height="120px"
              style={{ borderRadius: "50%" }}
            />
            <IconButton className="remove-button" onClick={handleRemoveFile}>
              <Cancel />
            </IconButton>
          </>
        )}
      </> */}
      <div ref={drop} className="FilesDragAndDrop" onClick={handleClick}>
        {dragging && (
          <div className="FilesDragAndDrop__placeholder" ref={drag}>
            Drop that file down low
          </div>
        )}
        <div
          className={`${
            disabled ? "disabledUploadDocument" : ""
          } FilesDragAndDrop__area`}
          style={style}
        >
          <FileUploadOutlinedIcon fontSize="large" />
          <span className="file-upload-text">
            <span className="file-link">Click here</span> or Drag and Drop to
            upload
          </span>
        </div>
      </div>
      <input
        type="file"
        accept={accept}
        ref={fileInput}
        style={{ display: "none" }}
        disabled={disabled}
        className={`${disabled ? "disabledUploadDocument" : ""}`}
        onChange={handleFileInputChange}
      />
    </>
  );
}
