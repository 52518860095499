import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState } from "react";

const CommanBanner = ({
  title,
  description,
  image,
  steps,
  collapsable = false,
  exploreNow = false,
  description1 = false,
}) => {
  const [collapse, setCollapse] = useState(true);
  return (
    <div className="image_col_bg">
      <h1 className="m-0 p-0">{title}</h1>
      {collapse && (
        <>
          <div>
            <p>{description}</p>
            {description1 && <p>{description1}</p>}
            <div
              className=""
              style={{
                display: "flex",
                columnGap: "40px",
                rowGap: "10px",
                margin: "40px 0",
                flexWrap: "wrap",
                width: "calc(100% - 200px)",
              }}
            >
              {steps &&
                steps?.map((step, index) => (
                  <div
                    key={index}
                    style={{
                      display: "flex",
                      gap: "20px",
                      alignItems: "center",
                    }}
                  >
                    <div className="dash-border">
                      <div className="bg-number">{`0${index + 1}`}</div>
                    </div>
                    <span className="image_col_bg_step_text">
                      {step?.title}
                    </span>
                  </div>
                ))}
            </div>
            {exploreNow && (
              <button
                className="button-primary banner-explore-btn"
                onClick={exploreNow}
              >
                Explore Now
              </button>
            )}
          </div>
          <img src={image} alt="" className="step_img" />
        </>
      )}
      {collapsable && (
        <div className="up_down" onClick={() => setCollapse(!collapse)}>
          {collapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </div>
      )}
    </div>
  );
};
export default CommanBanner;
