import React, { useRef, useState } from "react";
import { Button, Drawer } from "@mui/material";
import FormatAlignJustifyIcon from "@mui/icons-material/FormatAlignJustify";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { NavLink, useNavigate } from "react-router-dom";
import useOnClickOutside from "../hooks/useOnClickOutside";
import drawerClose from "../assets/images/drawer-close.svg";
import down from "../assets/images/down.svg";
import homeIns from "../assets/images/Home-ins.svg";
import floodIns from "../assets/images/Flood-ins.svg";
import logo from "../assets/images/logo.svg";
import styled from "styled-components";
import { getAuthUser, getAuthUserProfile } from "../http/services/local-storage-service";

const MenuDrawer = ({
  setLoginModal,
  isLayout,
  isPrivate,
  authUser,
  open,
  setOpen,
  handleLogout,
  scrollToDiv,
  openLoginModal,
  loginModal,
  closeLoginModal,
  setHelpModal
}) => {
  const navigate = useNavigate();
  const [isShown, setIsShown] = useState(false);

  const ref = useRef();
  useOnClickOutside(ref, () => (isShown ? setIsShown(false) : null));
  const navigation = (key) => {
    navigate(key);
    setOpen(false);
  };
  const MyPaper = styled.div`
    background-color: #09143a;
  `;
  const authUserProfile = getAuthUserProfile();
  const authUserLocalStore = getAuthUser();
  return (
    <div>
      <Button onClick={() => setOpen(true)} className="sidebarToggal">
        <FormatAlignJustifyIcon color="#000" />
      </Button>
      <></>
      <Drawer
        anchor="right"
        open={open}
        onClose={() => setOpen(false)}
        PaperProps={{ component: MyPaper }}
      >
        <div className="mainSideBar">
          {authUserLocalStore &&
                        authUserProfile ? (
            <>
              <div>
                <img
                  src={drawerClose}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                />
                <div className="sideBarUser">
                  <div className="sideBarUserIcon avatarUser">{`${authUser?.authUserProfile?.first_name?.charAt(
                    0
                  )}${authUser?.authUserProfile?.last_name?.charAt(0)}`}</div>
                  <h4 className="profileUserName cursor-pointer">
                    {`Hi, ${
                      authUser?.authUserProfile?.first_name.split(" ")?.length >
                        0 &&
                      authUser?.authUserProfile?.first_name
                        .split(" ")[0]
                        ?.slice(0, 10)
                    } ${authUser?.authUserProfile?.last_name?.slice(0, 1)}.`}
                  </h4>
                </div>
                <div className="sideBarTopMenu">
                  <span onClick={() => navigation("/overview")}>Overview</span>
                  <span onClick={() => navigation("/insurances")}>
                    Insurance Marketplace
                  </span>
                  <span onClick={() => navigation("/policy-vault")}>Vault</span>
                  <span
                    onClick={() =>
                      navigation("/insurance-verification-summary")
                    }
                  >
                    Insurance Verification Summary
                  </span>
                  <span onClick={() => navigation("/profile")}>My Profile</span>
                </div>
              </div>
              <div className="sideBarBottomMenu">
                <li
                  // onClick={() => {
                  //   navigation("/help");
                  // }}
                  onClick={() => setHelpModal(true)}
                >
                  <span>Help</span>
                </li>
                <li
                  onClick={() => {
                    navigation("/");
                    handleLogout();
                  }}
                >
                  <span>Logout</span>
                </li>
              </div>{" "}
            </>
          ) : (
            <>
              <div>
                <img
                  src={drawerClose}
                  style={{ cursor: "pointer" }}
                  onClick={() => setOpen(false)}
                />
                <div style={{ textAlign: "center" }}>
                  <img
                    width="136px"
                    alt="Angular Logo"
                    src={logo}
                    className="logoMain"
                    onClick={() => navigate("/")}
                  />
                </div>
                <div className="sideBarMenu">
                  <NavLink
                    onClick={() => {
                      scrollToDiv("about");
                      setOpen(false);
                    }}
                    to="#service"
                  >
                    About
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      scrollToDiv("service");
                      setOpen(false);
                    }}
                    to="#service"
                  >
                    Services
                  </NavLink>
                  <NavLink
                    onClick={() => {
                      scrollToDiv("insurance-marketplace");
                      setOpen(false);
                    }}
                    to="#insurance-marketplace"
                  >
                    Insurance Marketplace
                  </NavLink>
                  <NavLink
                    to="#join-us"
                    onClick={() => {
                      scrollToDiv("join-us");
                      setOpen(false);
                    }}
                  >
                    Join Us
                  </NavLink>
                  <NavLink
                    to="#lenders-servicers"
                    onClick={() => {
                      scrollToDiv("lenders-servicers");
                      setOpen(false);
                    }}
                  >
                    Lenders/Servicers
                  </NavLink>
                  <NavLink
                    to="#contact-us"
                    onClick={() => {
                      scrollToDiv("contact-us");
                      setOpen(false);
                    }}
                  >
                    Contact Us
                  </NavLink>
                </div>
              </div>
              {!isPrivate && (
                <div className="sideBar-login">
                  <button
                    className="loginBtn"
                    onClick={() => {
                      openLoginModal(!loginModal);
                      setOpen(false);
                    }}
                  >
                    Login
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default MenuDrawer;
