import { useEffect } from "react";

const useOnClickOutside = (ref, handler) => {
  useEffect(() => {
    const listener = (event) => {
      const datePickerClass = [
        "MuiPickersCalendarHeader-label",
        "MuiPickersYear-yearButton",
        "MuiPickersMonth-monthButton",
        "MuiDayCalendar-weekContainer",
        "MuiDayCalendar-weekDayLabel",
        "MuiPickersYear-root",
        "MuiPickersCalendarHeader-root",
        "MuiYearCalendar-root",
        "MuiPickersMonth-root",
        "MuiPickersSlideTransition-root",
        "MuiPickersArrowSwitcher-root",
        "MuiDateCalendar-root",
        "MuiPickersCalendarHeader-labelContainer",
        "MuiPickersCalendarHeader-switchViewButton",
        "MuiDayCalendar-header",
        "MuiPickersArrowSwitcher-button",
      ];
      const condition = datePickerClass?.some(
        (item) =>
          typeof event?.target?.className === "string" &&
          event?.target?.className?.split(" ")?.includes(item)
      );
      if (
        !ref.current ||
        ref.current.contains(event.target) ||
        event.target.id === "notOutside" ||
        event.target?.className === "loader" ||
        condition ||
        event?.target?.className?.animVal
      ) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
};

export default useOnClickOutside;
