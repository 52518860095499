import React from "react";
import moment from "moment";
import { useNavigate } from "react-router";
import noRecordFound from "../assets/images/noRecordFound.jpg";

import home from "../assets/images/Home.svg";
import NotFoundRecord from "../comman/NotFoundRecord";
import { Button, CircularProgress, Grid } from "@mui/material";
import { useAuth } from "../context/AuthContext";
const ActivityCard = ({
  activityRecord,
  loadMoreLead,
  loadMoreLoader,
  total = 0,
}) => {
  const navigate = useNavigate();
  const { authUser } = useAuth();
  const setCardColor = (key) => {
    switch (key) {
      case "active":
        return "active-card";
      case "draft":
        return "drafts-card";
      case "purchased":
        return "purchased-card";
      case "closed":
        return "closed-card";
      default:
        return "other-status-card";
        break;
    }
  };
  return (
    <Grid
      container
      spacing={5}
      style={activityRecord.length > 0 ? {} : { justifyContent: "center" }}
    >
      {activityRecord && activityRecord.length > 0 ? (
        activityRecord?.map((active, i) => (
          <Grid item xs={12} md={4} lg={3} key={i}>
            <div
              key={i}
              className={`card-active ${setCardColor(active.status)}`}
            >
              <div className="card-header">
                <div className="icon-bg-active-card">
                  <img
                    src={active.insurance_type?.icon}
                    alt={active.insurance_type?.title}
                  />
                </div>
                <div className="tag">{active?.status}</div>
              </div>
              <div>
                <h1>{`${active?.insurance_type?.title}`}</h1>
                <p>
                  {moment(moment.utc(active?.lead_origination_date).toDate())
                    .local(true)
                    .format("LT, MMM DD ")}
                </p>
                {/* 10:40PM, Mar 22 */}
              </div>
              {/* <div className="separate"></div> */}
              <div className="active-lead-btn">
                <Button
                  className="view_button quote-request"
                  onClick={() => {
                    if (active?.status === "draft") {
                      navigate(
                        `/preview/${active?.sequence_id}`
                      );
                    } else {
                      navigate("/quote-details", {
                        state: {
                          sequence_id: active?.sequence_id,
                          user_profile_sequence_id:
                            authUser?.authUserProfile?.sequence_id,
                          insurance_type: active.insurance_type,
                          status: active?.status,
                        },
                      });
                    }
                  }}
                >
                  View
                </Button>
                {/* <Button
                  className="view_button"
                  style={{ minWidth: "135px" }}
                  onClick={() =>
                    navigate(`/quote-details`, {
                      state: {
                        sequence_id: active?.sequence_id,
                        user_profile_sequence_id:
                          active?.user_profile_sequence_id,
                        insurance_type: active?.insurance_type,
                        status: active?.status,
                      },
                    })
                  }
                >
                  View Quote
                </Button> */}
              </div>
            </div>
          </Grid>
        ))
      ) : (
        <Grid item xs={12} md={6}>
          <NotFoundRecord title={"You have no ongoing activity!"} />
        </Grid>
      )}
      {total > 20 && activityRecord.length !== total && (
        <div className="lead-more-btn">
          <Button
            className="view_button"
            onClick={loadMoreLead}
            disabled={loadMoreLoader}
          >
            Load More{" "}
            {loadMoreLoader && (
              <>
                <CircularProgress color="inherit" size={20} />
              </>
            )}
          </Button>
        </div>
      )}
    </Grid>
  );
};

export default ActivityCard;
