import {myAxios} from "../axios";
import {environment} from "../../environment/environment";

export const getProfileDetailsApi = (payload) => {
  return myAxios.post(environment.api_endpoint + "/users", payload)
}

export const createAccountApi = (payload) => {
  return myAxios.post(environment.api_endpoint + "/users", payload)
}

export const updateProfileDetailsApi = (payload) => {
  return myAxios.post(environment.api_endpoint + "/users", payload)
}
