import {
  CircularProgress,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import logo from "../assets/images/logo.svg";
import promise from "../assets/images/our-promise-2.webp";
import { connectWebQuery } from "../http/services/api-payload-prepare";
import { environment } from "../environment/environment";
import { enqueueSnackbar } from "notistack";
import {
  handleEmailValidate,
  handleMobileValidateForProfile,
} from "../validations";
import { useState } from "react";
const initialValues = {
  name: "",
  email: "",
  phone_number: "",
  category: "",
  description: "",
  website_tracking: "",
};
const HelpDielog = ({ onClose, profileDetails }) => {
  const [value, setValue] = useState(initialValues);
  const [error, setError] = useState({});
  const [loader, setLoader] = useState(false);
  useState(() => {
    setValue({
      ...value,
      name: `${profileDetails?.first_name} ${profileDetails?.last_name}`,
      email: profileDetails?.email,
      phone_number: profileDetails?.phone_number,
    });
  }, [profileDetails]);
  const handleValidationError = () => {
    let error = {};
    error = {
      ...error,
      name: validationMassage("name", value?.name),
      email: validationMassage("email", value?.email),
      phone_number: validationMassage("phone_number", value?.phone_number),
      description: validationMassage("description", value?.description),
    };
    setError(error);
    if (
      error?.name?.length > 0 ||
      error?.email?.length > 0 ||
      error?.phone_number?.length > 0 ||
      error?.description?.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handelConnectForm = async (e) => {
    e?.preventDefault();
    if (handleValidationError()) {
      return true;
    }
    setLoader(true);
    const connectPayload = connectWebQuery({
      ...value,
      category: value?.category,
    });
    try {
      const response = await fetch(
        environment.api_open_endpoint + "/open-api",
        {
          method: "POST",
          headers: {},
          body: JSON.stringify(connectPayload),
        }
      );
      if (response?.status === 200) {
        const responseData = await response.json();
        onClose();
        setValue(initialValues);
        setError({});
        if (responseData?.message) {
          enqueueSnackbar(responseData?.message, {
            variant: "success",
          });
        } else {
          enqueueSnackbar("Something went wrong.!", {
            variant: "error",
          });
        }
      } else {
        enqueueSnackbar("Something went wrong.!", {
          variant: "error",
        });
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error(error, "error");
    }
  };
  const requiredField = ["name", "email", "phone_number", "description"];
  const validationMassage = (name, value) => {
    switch (name) {
      case "name":
        if (value?.length > 0) {
          if (!/^(?!.*<[^>]+>).*$/.test(value)) {
            return "Please enter valid name";
          } else {
            return "";
          }
        } else {
          return "This field is required";
        }
      case "email":
        if (value?.length > 0) {
          if (handleEmailValidate(value)) {
            return "";
          } else {
            return "Please enter valid email";
          }
        } else {
          return "This field is required";
        }
      case "phone_number":
        if (value?.length > 0) {
          if (handleMobileValidateForProfile(value)) {
            return "";
          } else {
            return "Please enter valid Phone number";
          }
        } else {
          return "This field is required";
        }
      case "description":
        if (value?.length > 0) {
          if (!/^(?!.*<[^>]+>).*$/.test(value)) {
            return "Please enter valid description";
          } else {
            return "";
          }
        }
    }
  };
  const handelConnectField = (e) => {
    const { name, value } = e?.target;
    if (requiredField?.includes(name)) {
      setError((preError) => {
        return {
          ...preError,
          [name]: validationMassage(name, value),
        };
      });
    }
    setValue((preVal) => {
      return { ...preVal, [name]: value };
    });
  };
  return (
    <div className="popupBg">
      <>
        <div className="logImgMain">
          <div className="popupContain">
            <img width="200px" alt="Angular Logo" src={logo} />
            <img alt="Angular Logo" className="illuuuu" src={promise} />
          </div>
        </div>
        <IconButton
          aria-label="delete"
          classes={{ root: "closeLoginModalButton" }}
          onClick={onClose}
        >
          <CloseOutlinedIcon />
        </IconButton>

        <form
          className="form_section"
          style={{ padding: "50px 50px" }}
          onSubmit={handelConnectForm}
        >
          <h1 className="main_heading m-0  ">Help!</h1>
          {/* <div
                className={`form_fild  active ${error?.name ? "error_fields" : ""
                  }`}
              >
                <p>
                  Name<span className="mandatory">&nbsp;*</span>
                </p>
                <input
                  type="text"
                  name="name"
                  value={value?.name}
                  onChange={handelConnectField}
                />
                <p>{error?.name}</p>
              </div> */}
          <TextField
            id={"textField_Name"}
            autoComplete="nope"
            type={"text"}
            name="name"
            label="Name"
            variant="outlined"
            margin="none"
            color="secondary"
            value={value?.name}
            error={!!error?.name}
            onChange={handelConnectField}
            placeholder="Name"
            fullWidth
            inputProps={{
              classes: { focused: "hello-world", root: "world" },
            }}
            helperText={error?.name ? error?.name : null}
          />
          {/* <div
                className={`form_fild  active ${error?.email ? "error_fields" : ""
                  }`}
              >
                <p>
                  Email<span className="mandatory">&nbsp;*</span>
                </p>
                <input
                  type="text"
                  name="email"
                  value={value?.email}
                  onChange={handelConnectField}
                />
                <p>{error?.email}</p>
              </div> */}
          <TextField
            id={"textField_email"}
            autoComplete="nope"
            type={"text"}
            name="email"
            label="Email"
            variant="outlined"
            margin="none"
            color="secondary"
            value={value?.email}
            error={!!error?.email}
            onChange={handelConnectField}
            placeholder="email"
            fullWidth
            inputProps={{
              classes: { focused: "hello-world", root: "world" },
            }}
            helperText={error?.email ? error?.email : null}
          />
          {/* <div
                className={`form_fild  active ${error?.phone_number ? "error_fields" : ""
                  }`}
              >
                <p>
                  Phone Number<span className="mandatory">&nbsp;*</span>
                </p>
                <input
                  type="text"
                  name="phone_number"
                  value={value?.phone_number}
                  onChange={handelConnectField}
                />
                <p>{error?.phone_number}</p>
              </div> */}
          <TextField
            id={"textField_phone_number"}
            autoComplete="nope"
            type={"text"}
            name="phone_number"
            label="Phone Number"
            variant="outlined"
            margin="none"
            color="secondary"
            value={value?.phone_number}
            error={!!error?.phone_number}
            onChange={handelConnectField}
            placeholder="Phone Number"
            fullWidth
            inputProps={{
              classes: { focused: "hello-world", root: "world" },
            }}
            helperText={error?.phone_number ? error?.phone_number : null}
          />
          {/* <div className="form_fild  active"> */}
          {/* <p style={{ marginBottom: "10px" }}>I want to</p> */}
          <FormControl fullWidth className={`select-box-landing`}>
            <InputLabel id={"demo-simple-select-standard-label"}>
              I want to
            </InputLabel>
            <Select
              // IconComponent={() => (
              //   <ExpandMoreIcon
              //     style={{
              //       color: "#09143a",
              //       fontSize: "30px",
              //     }}
              //   />
              // )}
              labelId="demo-simple-select-standard-label"
              value={value?.category}
              label="I want to"
              name="category"
              onChange={handelConnectField}
              variant="outlined"
              // className="contact-select-box"
              inputProps={{
                classes: {
                  focused: "hello-world",
                  root: "world",
                },
              }}
              displayEmpty
            >
              {/* <MenuItem value="" disabled>
                    Select an option
                  </MenuItem> */}
              <MenuItem value="Ask a Question">Ask a Question</MenuItem>
              <MenuItem value="Partner with YOLOH">Partner with YOLOH</MenuItem>
              <MenuItem value="Request a Demo">Request a Demo</MenuItem>
              <MenuItem value="Raise a Ticket">Raise a Ticket</MenuItem>
              <MenuItem value="Raise a Concern with My Quote">
                Raise a Concern with My Quote
              </MenuItem>
            </Select>
          </FormControl>
          {/* </div> */}
          {/* <div className="form_fild  active">
                <p >Description</p>
                <textarea
                  rows={3}
                  name="description"
                  value={value?.description}
                  onChange={handelConnectField}
                />
              </div> */}
          <TextField
            id={"textField_description"}
            autoComplete="nope"
            type={"text"}
            name="description"
            label="Description"
            variant="outlined"
            margin="none"
            color="secondary"
            value={value?.description}
            error={!!error?.description}
            onChange={handelConnectField}
            placeholder="Description"
            fullWidth
            inputProps={{
              classes: { focused: "hello-world", root: "world" },
            }}
            helperText={error?.description ? error?.description : null}
          />
          <button
            className="submit_button  active"
            type="submit"
            style={{ display: "flex", margin: 0, alignItems: "center" }}
            disabled={loader}
          >
            Submit&nbsp; &nbsp;{" "}
            {loader && <CircularProgress color="inherit" size={25} />}
          </button>
        </form>
      </>
    </div>
  );
};
export default HelpDielog;
