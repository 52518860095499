import { Container, Drawer } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";
import dashboard from "../assets/images/Dashboard-Icon.svg";
import activity from "../assets/images/Activity-Icon.svg";
import history from "../assets/images/History.svg";
import help from "../assets/images/Help-Icon.svg";
import settings from "../assets/images/Settings.svg";
import logoutIcon from "../assets/images/logout.svg";
import { useAuth } from "../context/AuthContext";
const title = {
  dashboard: "Dashboard",
  activity: "Activity",
  "transaction-history": "Transaction History",
  setting: "Setting",
  "quote-details": "Quote Details",
};
const DrawerIndex = ({ children }) => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const { logout } = useAuth();
  const navigate = useNavigate();
  const header = useMemo(() => {
    const titles = location?.pathname.split("/");
    if (titles.length > 2) {
      return titles[2] ? titles[2] : "dashboard";
    }
  }, [location?.pathname]);

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  return (
    <>
      <div className="layout bglight">
        <section className="main-dashboard">
          <div className="activity-dashboard">
            {/* <div className="d-flex activity-dashboard-header justify-between align-items: center;">
              <h1 className="m-0 p-0 self-center">{title[header]}</h1>
            </div> */}
            {children}
          </div>
        </section>
        <div>
          <Drawer anchor="left" open={open} onClose={() => setOpen(false)}>
            <div className="sidebarHeader">
              <h4>
                YOLO<span>H</span>
              </h4>
              {/* <CloseOutlinedIcon onClick={() => setOpen(false)} /> */}
            </div>
            <div className="mainSideBar">
              <NavLink
                to="/overview/"
                className="d-flex align-items-center gap-10"
              >
                <img src={dashboard} alt="img" />
                <div>Dashboard</div>
              </NavLink>
              <NavLink
                to="/activity"
                className="d-flex align-items-center gap-10"
              >
                <img src={activity} alt="img" />
                <div>Activity</div>
              </NavLink>
              {/*<NavLink
                to="/dashboard/transaction-history"
                className="d-flex align-items-center gap-10"
              >
                <img src={history} alt="img" />
                <div>Transaction History</div>
              </NavLink>*/}
              <NavLink
                to="/help"
                className="d-flex align-items-center gap-10"
              >
                <img src={help} alt="img" />
                <div>Help</div>
              </NavLink>
              {/*<NavLink
                to="/dashboard/setting"
                className="d-flex align-items-center gap-10"
              >
                <img src={settings} alt="img" />
                <div>Settings</div>
              </NavLink>*/}
              <p
                onClick={handleLogout}
                className="d-flex align-items-center gap-10 link"
              >
                <img src={logoutIcon} alt="img" />
                <div>Logout</div>
              </p>
            </div>
          </Drawer>
        </div>
      </div>
    </>
  );
};

export default DrawerIndex;
