import { lazy } from "react";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Licenses from "../pages/Licenses​";
import Terms from "../pages/Terms";
const lending = lazy(() => import("../pages/Landing"));
const PreviewInsurance = lazy(() =>
  import("../pages/insurance/PreviewInsurance")
);
const InsuranceForm = lazy(() => import("../pages/insurance"));
const product = lazy(() => import("../pages/Product"));
const dashboard = lazy(() => import("../pages/Dashboard"));
const verificationInsurance = lazy(() =>
  import("../pages/VerificationInsurance")
);
const quoteDetails = lazy(() => import("../pages/QuoteDetails"));
const loanDetail = lazy(() => import("../pages/LoanDetail"));
const transactionHistory = lazy(() => import("../pages/TransactionHistory"));
const helpPage = lazy(() => import("../pages/Chats/HelpPage"));
const settings = lazy(() => import("../pages/Settings"));
const profile = lazy(() => import("../pages/Profile"));
const agent = lazy(() => import("../pages/Chats/Agent"));
const Insurances = lazy(() => import("../pages/insurances"));
const insuranceOption = lazy(() => import("../pages/InsuranceOption"));
const policyVault = lazy(() => import("../pages/PolicyVault"));
const verificationInsuranceForm = lazy(() =>
  import("../pages/VerificationInsuranceForm")
);
const policyVaultOption = lazy(() => import("../pages/PolicyVaultOption"));
const notification = lazy(() => import("../pages/Notification"));
export const routes = [
  {
    element: lending,
    path: "/lender_user/:key/:id",
    isPrivate: false,
    isLayout: true,
    isView: true,
    isFooter: true,
  },
  {
    element: lending,
    path: "/ivs/:key/:id",
    isPrivate: false,
    isLayout: true,
    isView: true,
    isFooter: true,
  },
  {
    element: product,
    path: "/product",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: PrivacyPolicy,
    path: "/privacy-policy",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: Licenses,
    path: "/licenses",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: Terms,
    path: "/terms",
    isPrivate: false,
    isLayout: false,
    isView: true,
    isFooter: false,
  },
  {
    element: dashboard,
    path: "/overview",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: verificationInsurance,
    path: "/insurance-verification-summary",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: quoteDetails,
    path: "/quote-details",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: false,
    isHeader: false,
  },
  {
    element: transactionHistory,
    path: "/dashboard/transaction-history",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: helpPage,
    path: "/help",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: false,
    isHeader: false,
  },
  {
    element: settings,
    path: "/dashboard/setting",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: profile,
    path: "/profile",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isHeader: false,
    isFooter: false,
  },
  {
    element: agent,
    path: "/dashboard/agent",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: false,
    isHeader: false,
  },

  {
    element: InsuranceForm,
    path: "/insurance",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: true,
    isHeader: false,
  },
  {
    element: PreviewInsurance,
    path: "/preview",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: true,
    isHeader: false,
  },
  {
    element: InsuranceForm,
    path: "/insurance/:id",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: true,
    isHeader: false,
  },
  {
    element: PreviewInsurance,
    path: "/preview/:id",
    isPrivate: true,
    isLayout: false,
    isView: true,
    isFooter: true,
    isHeader: false,
  },
  {
    element: Insurances,
    path: "/insurances",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: policyVault,
    path: "/policy-vault",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: insuranceOption,
    path: "/insurance/:key",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
    isHeader: false,
  },
  {
    element: policyVaultOption,
    path: "/policy-vault/add-policy",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
    isHeader: false,
  },
  {
    element: notification,
    path: "/notification",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
  },
  {
    element: loanDetail,
    path: "/loan_details",
    isPrivate: true,
    isLayout: true,
    isView: true,
    isFooter: false,
    isHeader: false,
  },
];
