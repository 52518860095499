import React, { useState } from "react";
import { Grid } from "@mui/material";
import "./Landing.css";

const countryData = [
  {
    title: "AL",
    detail: "Alabama - 3002565094",
  },
  {
    title: "AK",
    detail: "Alaska - 3002572216",
  },
  {
    title: "AZ",
    detail: "Arizona - 3002565084",
  },
  {
    title: "AR",
    detail: "Arkansas - 3002565083",
  },
  {
    title: "CA",
    detail: "California - 6011071",
  },
  {
    title: "CO",
    detail: "Colorado - 798927",
  },
  {
    title: "CT",
    detail: "Connecticut - 3002565161",
  },
  {
    title: "DE",
    detail: "Delaware - 3002565095",
  },
  {
    title: "DC",
    detail: "Dist. Of Columbia - 3002565086",
  },
  {
    title: "FL",
    detail: "Florida - L128072",
  },
  {
    title: "GA",
    detail: "Georgia - 230334",
  },
  {
    title: "HI",
    detail: "Hawaii - 3002565131",
  },
  {
    title: "ID",
    detail: "Idaho - 3002573644",
  },
  {
    title: "IL",
    detail: "Illinois - 3002565097",
  },
  {
    title: "IN",
    detail: "Indiana - 3909837",
  },
  {
    title: "IA",
    detail: "Iowa - 3002565063",
  },
  {
    title: "KS",
    detail: "Kansas - 3002565096",
  },
  {
    title: "KY",
    detail: "Kentucky - 1279609",
  },
  {
    title: "LA",
    detail: "Louisiana - 1068671",
  },
  {
    title: "ME",
    detail: "Maine - AGN454547",
  },
  {
    title: "MD",
    detail: "Maryland - 3002565109",
  },
  {
    title: "MA",
    detail: " Massachusetts - 3002799264",
  },
  {
    title: "MI",
    detail: " Michigan - 0143981",
  },
  {
    title: "MN",
    detail: "Minnesota - 40869557",
  },
  {
    title: "MS",
    detail: "Mississippi - 15046768",
  },
  {
    title: "MO",
    detail: "Missouri - 3002604196",
  },
  {
    title: "MT",
    detail: "Montana - 3002568249",
  },
  {
    title: "NE",
    detail: "Nebraska - 3002565119",
  },
  { title: "NV", detail: "Nevada - 3910188" },
  { title: "NH", detail: "New Hampshire - 3002568055" },
  { title: "NJ", detail: "New Jersey - 3002565105" },
  { title: "NM", detail: "New Mexico - 3002628470" },
  { title: "NY", detail: "New York - LA-1822871 PC-1822871" },
  { title: "NC", detail: "North Carolina - 3002565143" },
  { title: "ND", detail: "North Dakota - 3002565113" },
  { title: "OH", detail: "Ohio - 1511308" },
  { title: "OK", detail: "Oklahoma - 3002565018" },
  { title: "OR", detail: "Oregon - 3002611592" },
  { title: "PA", detail: "Pennsylvania - 1134805" },
  { title: "RI", detail: "Rhode Island - 3002565103" },
  { title: "SC", detail: "South Carolina - 3002565114" },
  { title: "SD", detail: "South Dakota - 10030885" },
  { title: "TN", detail: "Tennessee - 3002565148" },
  { title: "TX", detail: "Texas - 3024751" },
  { title: "UT", detail: "Utah - 988632" },
  { title: "VT", detail: "Vermont - 3002565091" },
  { title: "VA", detail: "Virginia - 157349" },
  { title: "WA", detail: "Washington - 1212356" },
  { title: "WV", detail: "West Virginia - 3002573119" },
  { title: "WI", detail: "Wisconsin - 3002569437" },
  { title: "WY", detail: "Wyoming - 557821" },
  { title: "WY", detail: "Wyoming - 557821" },
];

const Licenses = () => {
  const [licenseArray, setLicenseArray] = useState([...countryData]);

  return (
    <section className="static_page">
      <div className="container_width">
        <h1>YOLOH State Licenses</h1>
        <Grid container spacing={5}>
          {licenseArray.map((record, i) => (
            <Grid sm={12} md={6} lg={6} item={true} key={i}>
              <div className="licenses_box">
                <div style={{ fontFamily: "jokker-bold" }}>{record?.title}</div>
                <div style={{ fontFamily: "jokker-bold" }}>
                  {record?.detail}
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </section>
  );
};

export default Licenses;
